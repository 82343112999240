import { useSnackBarMessage, STATUS } from 'hooks/useSnackbar';
import { useCheckPRCRoute } from 'hooks/useCheckPRCRoute';
import { useNavigate } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { useStyles } from './utils/style';
import { api } from 'utils/API';

import PasswordField from 'components/Form/Fields/PasswordField';
import Textfield from 'components/Form/Fields/TextField';
import CardContent from '@mui/material/CardContent';
import ValidationSchema from './utils/validation';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import logo from '../../assets/images/logo.png';
import FormikForm from 'components/Form/Formik';
import Button from 'components/Form/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Base64 from 'base-64';

const REGISTRATION_ROUTE = '/sign-up';
const MEMBER_FP_ROUTE = '/account/recovery';
const PRC_FP_ROUTE = '/prc/account/recovery';
const PRC_LOGIN_ROUTE = '/prc/authentication';
const STEP_ROUTES = {
	0: '/registration/form/step-five',
	1: '/registration/form/step-five',
	2: '/registration/form/step-three',
	3: '/registration/form/step-two',
	4: '/registration/form/step-one'
};

export default function LoginPage() {
	const navigate = useNavigate();
	const classes = useStyles();

	const { isPRCRoute } = useCheckPRCRoute();
	const { setSnackBarMessage } = useSnackBarMessage();
	const [form] = useState({
		action: 'login',
		email: '',
		password: ''
	});

	// Step 2 : Redirect based on the condition
	const handleRedirect = (data) => {
		const { reg_step, id, is_active, is_account_exp, exp_date, paid } = data;

		const encodedId = Base64.encode(id);
		const isPaid = parseInt(reg_step) === 0 && paid === true;
		const isNew = paid === true;
		const isUnpaidActive = parseInt(is_active) === 1 && paid === false;

		if (isPaid || isNew || isUnpaidActive || is_account_exp) {
			// April 25, 2022
			const encodedId = Base64.encode(id);
			const token = Base64.encode(
				JSON.stringify({ id, encodedId, is_active, exp_date })
			);
			localStorage.setItem('token', token);
			window.location.reload();
		} else {
			navigate({
				pathname: STEP_ROUTES[reg_step],
				search: `?param=${encodedId}`
			});
		}
	};

	// temporary
	// we should use token with jwt
	const handlePrcRedirect = ({ login_expiration, member_id }) => {
		const token = Base64.encode(
			JSON.stringify({ login_expiration, member_id })
		);
		localStorage.setItem('prc_token', token);
		window.location.reload();
	};

	// Step 1 : Login first and get the response
	const handleLogin = async (values) => {
		const endpoint = '/account.php';

		let payload = values;
		const { email, password } = values;

		if (isPRCRoute) {
			payload = { action: 'prc_login', email, password };
		}

		const response = await api.post(endpoint, payload).then((e) => e.data);

		try {
			if (response?.status === 200) {
				if (isPRCRoute) {
					await handlePrcRedirect(response);
				} else {
					await handleRedirect(response);
				}
			} else if (response?.status !== 200) {
				throw response?.message;
			}
		} catch (error) {
			setSnackBarMessage(error, STATUS.ERROR);
		}
	};

	const handleGoForgotPassword = () => {
		if (isPRCRoute) navigate(PRC_FP_ROUTE);
		else navigate(MEMBER_FP_ROUTE);
	};

	const handleGoPRCLogin = () => {
		if (!isPRCRoute) navigate(PRC_LOGIN_ROUTE);
	};

	return (
		<Fragment>
			<Grid container className={classes.container}>
				<Card className={classes.card}>
					<CardContent>
						<FormikForm
							initialValues={{ ...form }}
							validationSchema={ValidationSchema}
							enableReinitialize={true}
							onSubmit={(value) => {
								handleLogin(value);
							}}
						>
							<Grid container className={classes.cardContainer}>
								<Grid item xs={12} align="center">
									<img
										src={logo}
										height="60vw"
										alt="company-logo"
										style={{
											paddingTop: 50,
											paddingBottom: 40
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="h3" component="div">
										<Box
											fontSize={35}
											fontWeight={700}
											sx={{ color: 'text.secondary' }}
										>
											Welcome back!
										</Box>
									</Typography>
									<Typography gutterBottom variant="h6" component="div">
										<Box
											fontSize={18}
											sx={{ color: '#7E7E7E', fontWeight: 700 }}
										>
											{isPRCRoute
												? 'PRC Login to Continue'
												: 'Login to continue'}
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={12} sx={{ py: 1 }}>
									<Textfield
										label="Email Address"
										name="email"
										placeholder="Ex:  juandelacruz@email.com"
										icon={
											<EmailIcon sx={{ color: 'primary.main', fontSize: 15 }} />
										}
									/>
								</Grid>
								<Grid item xs={12} sx={{ py: 1 }}>
									<PasswordField
										label="Password"
										name="password"
										placeholder="Ex: Juan2345"
									/>
								</Grid>
								<Grid
									item
									container
									align="space-between"
									xs={12}
									sx={{ py: 1 }}
								>
									<Grid item xs={12} md={6}>
										<Box my={1}>
											<Button fullWidth variant="contained">
												Login
											</Button>
										</Box>
									</Grid>
									<Grid item xs={12} md={6} align="end" justify="center">
										<Box my={3}>
											<Link
												variant="body1"
												component="button"
												onClick={() => handleGoForgotPassword()}
											>
												Forgot Password
											</Link>
										</Box>
									</Grid>
									{!isPRCRoute && (
										<Grid item xs={12} md={12} align="end">
											<Box my={2}>
												New user?&nbsp;
												<Link
													component="button"
													variant="body1"
													onClick={() => {
														navigate(REGISTRATION_ROUTE);
													}}
												>
													Click here to REGISTER
												</Link>
											</Box>
										</Grid>
									)}
									{!isPRCRoute && (
										<Grid item xs={12} md={12} align="end" justify="center">
											<Box my={1}>
												<Link
													variant="body1"
													component="button"
													onClick={() => handleGoPRCLogin()}
												>
													For PRC login, click here
												</Link>
											</Box>
										</Grid>
									)}
								</Grid>
							</Grid>
						</FormikForm>
					</CardContent>
				</Card>
			</Grid>
		</Fragment>
	);
}
