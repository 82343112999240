import { usePostRequest } from 'helper/post.api';
import { useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';

import Select from 'components/Form/Fields/Select';

export default function Events({ handleChange, ...otherProps }) {
	const { loading, data, setPOST } = usePostRequest('/events.php', true);
	const { getData } = useAuth();
	const { id } = getData();
	const [options, setOptions] = useState([]);
	const configDropdownSelect = {
		...otherProps,
		options,
		loading,
		handleChange: (e) => {
			if (handleChange instanceof Function) {
				const { result } = data ?? []; // get the whole events
				const row = result.filter((d) => d.id === e.target.value)[0] ?? {};
				handleChange(row);
			}
		}
	};

	// this will trigger handlePostRequest
	useEffect(() => {
		const handlePostRequest = () => {
			setPOST({ action: 'get_events', id });
		};

		handlePostRequest();
	}, [id]);

	// set the key value paire format
	useEffect(() => {
		const { result } = data ?? [];
		const filteredResult = result?.filter(
			(items) => parseInt(items.has_registered) === 1
		);

		setOptions(
			filteredResult?.map((items) => ({
				text: items.e_title,
				value: items.id
			}))
		);
	}, [data]);

	return <Select {...configDropdownSelect} />;
}
